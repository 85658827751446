import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "../../components/GlobalStyle";
import { FourOhFour } from "../../pages/404/404";
// @ts-ignore
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import { PCDLandingPage } from "../../pages/pcd/Index";

/**
 * This is the root of the application.
 */
export function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={PCDLandingPage} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
