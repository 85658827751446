import React, { useEffect } from "react";
import { Container } from "./Style";

export function PCDLandingPage() {
  useEffect(() => {
    // this page used to be here, but has now moved.
    window.location.href = "https://pcd.team";
  });

  return (
    <Container>
      <div className="content-container">
        <div className="content"></div>
      </div>
    </Container>
  );
}
