import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  line-height: 120%;

  .content {
    max-width: 100vw;
    width: 600px;
    padding: 64px 32px;
  }

  .content-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  ol {
    padding-left: 30px;
  }

  li {
    margin-bottom: 1em;
  }
`;
